<template>
    <!--编辑页与详情页相同-->

    <div class="AllocateDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" label-width="80px" width="auto" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="类型">
                            <el-select placeholder="请选择" v-model="form.allocateType" disabled>
                                <el-option label="请选择" value="" />
                                <el-option label="内部调拨" :value="1" />
                                <el-option label="自建调拨" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调入机构">
                            <el-input v-model="form.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调出机构">
                            <el-input v-model="form.repoName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="form.createTime" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户" v-show="showCustomer">
                            <el-input readonly v-model="form.customer" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户电话" v-show="showCustomer">
                            <el-input readonly v-model="form.customerMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址" v-show="showCustomer">
                            <el-input readonly v-model="form.customerAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <ef-remark v-model="form.remark" readonly />
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
            <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.stock.allocate.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                v-loading="tableLoading"
                size="small"
                show-summary
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" min-width="120">
                    <template slot-scope="scope">
                        {{ scope.row.sku.name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.bars.map((d) => d).join(' / ') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.type | goodsType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="助记码" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.fastBar }}
                    </template>
                </el-table-column>
                <el-table-column label="自编码" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.customBar }}
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.sku.specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="180">
                    <template slot-scope="scope">
                        {{ scope.row.sku.category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80">
                    <template slot-scope="scope">
                        {{ scope.row.sku.unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.sku.brand }}
                    </template>
                </el-table-column>
                <el-table-column label="调拨价" width="120" v-if="showInPrice()">
                    <template slot-scope="scope">
                        {{ scope.row.prices }}
                    </template>
                </el-table-column>
                <el-table-column prop="counts" label="调拨数量" width="120" />
                <el-table-column prop="money" label="调拨金额" width="120" v-if="showInPrice()" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import PickupReturnAudit from 'components/PickupReturnAudit';
import EfRemark from 'components/EfRemark';

export default {
    name: 'AllocateDetail',
    props: ['form'],
    components: { PickupReturnAudit, EfRemark },
    data() {
        return {
            baseUrl: this.$efApi.stockAllocateApi.baseUrl,
            url: {
                goods: '/goods/goods/list_c',
                review: '/biz/review/list?bizCode=',
            },
            reviewData: [],
            codes: [],
            tableData: [],
            tableLoading: true,
        };
    },
    mounted() {
        this.codes = this.form.goodsCode;
        this.handleQuery();
    },
    computed: {
        showCustomer() {
            return !Util.isEmpty(this.form.outDeptCode);
        },
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl);
        },
        handleQuery() {
            UrlUtils.QueryRemote(this, this.url.review + this.form.code, (data) => {
                this.reviewData = data;
            });

            UrlUtils.QueryGoodsInfoByCodes(this, this.url.goods, { codes: this.codes }, (data) => {
                data.forEach((v, i) => {
                    v.prices = this.fmtMFour(this.form.prices[i]);
                    v.counts = this.form.counts[i];
                    v.money = this.fmtMFour(this.decimal(this.form.prices[i]).mul(v.counts).toNumber());
                });
                this.tableData = data;
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
